(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var angular = require("angular");
var _ = require("lodash");
var visibleSize = require("../utils/visible-size");

angular.module("ticTacToe")
    .directive("board", board);



var GRID_COLOR = "#eee";
var PIECE_COLOR = "#000";


function board(GAME_EVENTS, PIECES, $window, $timeout, $log) {
    return {
        restrict: "E",
        template: '<canvas style="margin: auto; display: block"></canvas>',
        scope: {
            numRows: "=",
            numCols: "="
        },
        link: link
    };

    function link($scope, iElem) {

        var canvas = iElem.find("canvas")[0];
        var ctx = canvas.getContext("2d");

        var numRows, numCols;

        var cellSize; // square cell width = height
        var GRID_LINE_WIDTH = 1;

        var canvasWidth, canvasHeight;
        var windowInnerWidth;

        var board;
        var lastTurnResult;
        var highlightedResult;


        $scope.$watch("numRows", function(value) {
            numRows = value;
            resetBoard();
            resizeAndDrawCanvas(true);
        });
        $scope.$watch("numCols", function(value) {
            numCols = value;
            resetBoard();
            resizeAndDrawCanvas(true);
        });

        $window.addEventListener("resize", _.debounce(function() {
            resizeAndDrawCanvas(false);
        }, 150));

        $scope.$on(GAME_EVENTS.GAME_STARTED, onGameStarted);
        $scope.$on(GAME_EVENTS.MOVE_COMPLETED, onMoveCompleted);
        $scope.$on(GAME_EVENTS.RESIZE_BOARD, function() {
            resizeAndDrawCanvas(true);
        });
        // $scope.$on(GAME_EVENTS.SHOW_LAST_MOVE, highlightLastMove);

        canvas.onclick = onCanvasClick;


        //---------------------------------------------------//


        function resetBoard() {
            board = undefined;
            lastTurnResult = undefined;
            if (numRows > 0 && numCols > 0) {
                board = [];
                for (var i = 0; i < numRows; i++) {
                    board[i] = [];
                    for (var j = 0; j < numCols; j++) {
                        board[i][j] = null;
                    }
                }
            }
        }


        function resizeAndDrawCanvas(force) {
            if (!force && windowInnerWidth === window.innerWidth) {
                return;
            }
            var resultToReHighlight = highlightedResult
            removeResultHighlighting()
            if (numRows && numCols) {
                resizeCanvas();
                drawGameBoard();
                windowInnerWidth = window.innerWidth;
            }
            if (resultToReHighlight) {
                highlightResult(resultToReHighlight)
            }
            if (board) {
                drawPieces();
            }
        }


        function resizeCanvas() {
            // We assume that the parent container can adapt to content vertically but not horizontally.

            // 1st pass: resize to available width
            cellSize = _.floor(canvas.parentNode.clientWidth/numCols); // square cells
            canvasWidth = numCols*cellSize;
            canvasHeight = numRows*cellSize;

            // also clears canvas
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            // 2nd pass: resize to visible height
            var visibleHeight = visibleSize(canvas).height;

            cellSize = _.floor(Math.min(canvas.clientWidth/numCols, visibleHeight/numRows));
            canvasWidth = numCols*cellSize;
            canvasHeight = numRows*cellSize;

            // also clears canvas
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
        }

        function drawGameBoard() {
            ctx.strokeStyle = GRID_COLOR;
            ctx.lineWidth = GRID_LINE_WIDTH;

            // Draw horizontal lines
            for (var i = 0; i < numRows - 1; i++) {
                ctx.beginPath();
                ctx.moveTo(0, cellSize*(i + 1));
                ctx.lineTo(canvas.width, cellSize*(i + 1));
                ctx.stroke();
            }

            // Draw vertical lines
            for (var j = 0; j < numCols - 1; j++) {
                ctx.beginPath();
                ctx.moveTo(cellSize*(j + 1), 0);
                ctx.lineTo(cellSize*(j + 1), canvas.height);
                ctx.stroke();
            }

        }


        function drawPieces() {
            for (var i = 0; i < numRows; i++) {
                for (var j = 0; j < numCols; j++) {
                    var cell = {row: i, column: j};
                    if (board[i][j] === PIECES.X) {
                        drawCross(cell);
                    } else if (board[i][j] === PIECES.O) {
                        drawCircle(cell);
                    }
                }
            }
            if (lastTurnResult && lastTurnResult.winningSequence) {
                drawLine(lastTurnResult.winningSequence.start, lastTurnResult.winningSequence.end);
            }
        }

        function onGameStarted(event, game) {
            lastTurnResult = null
            highlightedResult = null

            numRows = game.board.length;
            numCols = game.board[0].length;
            board = _.cloneDeep(game.board);

            resizeCanvas();
            drawGameBoard();
        }

        function onMoveCompleted(event, result) {
            board[result.move.cell.row][result.move.cell.column] = result.move.piece;
            if (result.highlight) {
                removeResultHighlighting()
                highlightResult(result)
            }
            drawResult(result)
            lastTurnResult = result;
        }

        function drawResult(result) {
            if (result.move.piece === PIECES.O) {
                drawCircle(result.move.cell);
            } else if (result.move.piece === PIECES.X) {
                drawCross(result.move.cell);
            }

            if (result.winningSequence) {
                drawLine(result.winningSequence.start, result.winningSequence.end);
            }
        }

        function drawCross(cell) {
            ctx.strokeStyle = PIECE_COLOR;

            ctx.beginPath();

            // Upper left to lower right
            ctx.moveTo(cell.column*cellSize + 0.2*cellSize, cell.row*cellSize + 0.2*cellSize);
            ctx.lineTo((cell.column + 1)*cellSize - 0.2*cellSize, (cell.row + 1)*cellSize - 0.2*cellSize);

            // Upper right to lower left
            ctx.moveTo((cell.column + 1)*cellSize - 0.2*cellSize, cell.row*cellSize + 0.2*cellSize);
            ctx.lineTo(cell.column*cellSize + 0.2*cellSize, (cell.row + 1)*cellSize - 0.2*cellSize);

            ctx.lineWidth = 3;
            ctx.stroke();
        }


        function drawCircle(cell) {
            ctx.strokeStyle = PIECE_COLOR;

            ctx.beginPath();
            ctx.arc((cell.column + 1/2)*cellSize, (cell.row + 1/2)*cellSize, (cellSize - 3)/2 - 0.1*cellSize, 0, 2*Math.PI);
            ctx.lineWidth = 3;
            ctx.stroke();
        }

        function getCellBackgroundRectParams(cell) {
            return {
                x: cell.column * cellSize + GRID_LINE_WIDTH,
                y: cell.row * cellSize + GRID_LINE_WIDTH,
                w: cellSize -2 * GRID_LINE_WIDTH,
                h: cellSize -2 * GRID_LINE_WIDTH
            }
        }

        function highlightResult(result) {
            ctx.beginPath()
            ctx.fillStyle = "yellow"
            const rect = getCellBackgroundRectParams(result.move.cell)
            ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
            ctx.stroke()
            highlightedResult = result
        }

        function removeResultHighlighting() {
            if (highlightedResult) {
                ctx.beginPath()
                const rect = getCellBackgroundRectParams(highlightedResult.move.cell)
                ctx.clearRect(rect.x, rect.y, rect.w, rect.h)
                ctx.stroke()
                drawResult(highlightedResult)
                highlightedResult = null
            }
        }


        function drawLine(start, end) {
            var startX, startY, endX, endY;

            startX = (start.column + 1/2)*cellSize;
            startY = (start.row + 1/2)*cellSize;
            endX = (end.column + 1/2)*cellSize;
            endY = (end.row + 1/2)*cellSize;

            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX, endY);

            ctx.strokeStyle = "red";
            ctx.lineWidth = 4;
            ctx.stroke();
        }


        function onCanvasClick(e) {
            var cc = getCanvasCoordinates(e);
            var cell = getCellCoordinates(cc);

            $scope.$emit(GAME_EVENTS.MOVE_SELECTED, cell);
        }


        function getCanvasCoordinates(clickEvent) {
            var rect = canvas.getBoundingClientRect();
            return {
                x: clickEvent.pageX - rect.left,
                y: clickEvent.pageY - rect.top
            };
        }


        function getCellCoordinates(canvasCoordinates) {
            return {
                row: _.floor(canvasCoordinates.y/cellSize),
                column: _.floor(canvasCoordinates.x/cellSize)
            };
        }
    }
}










},{"../utils/visible-size":9,"angular":"angular","lodash":"lodash"}],2:[function(require,module,exports){
require("./board-directive");
},{"./board-directive":1}],3:[function(require,module,exports){
"use strict";

var angular = require("angular");
var _ = require("lodash");

angular.module("ticTacToe")
    .controller("GameController", GameController)
    .controller("GameController.ToastController", ToastController);

function GameController(GAME_EVENTS, PIECES, PLAYER_TYPES, gameService, $scope, $q, $mdToast, spinnerOverlay, $mdMedia, $timeout) {
    var vm = this;
    var deferredMove;
    var boardSpinner;

    vm.init = init;
    vm.startGame = startGame;
    vm.setPaused = setPaused;
    vm.endGame = endGame;
    vm.toggleConfigMode = toggleConfigMode;

    $scope.$on(GAME_EVENTS.MOVE_SELECTED, selectHumanPlayerMove);

    $scope.$watch(screenIsSmall, handleSmallScreen);


    init();


    function init() {
        vm.gameExists = false;
        vm.paused = false;
        vm.showConfig = false;
        vm.screenIsSmall = screenIsSmall();
        boardSpinner = spinnerOverlay("board-container");
        resetStats();

        return $q.all({
            playerList: gameService.getPlayers(),

            PIECES: PIECES,

            gameConfig: {
                connectHowMany: 5,
                firstPlayer: "RANDOM",
                board: {
                    rows: vm.screenIsSmall ? 15 : 18,
                    columns: vm.screenIsSmall ? 15 : 18
                },
                players: {},
                rounds: 1
            }

        }).then(function(data) {
            _.extend(vm, data);

            vm.gameConfig.players[PIECES.X] = vm.playerList[0];
            vm.gameConfig.players[PIECES.O] = vm.playerList[1];
        });

    }

    function startGame() {
        return resetStats()
            .then(initRound)
            .then(function() {
                if (isAiVsAiGame()) {
                    showProgressToast("Playing AI vs AI...");
                }
            })
            .then(play);
    }

    function initRound() {
        boardSpinner.show();

        var gameConfig = _.cloneDeep(vm.gameConfig);
        if (gameConfig.firstPlayer === 'RANDOM') {
            gameConfig.firstPlayer = _.values(PIECES)[_.random(1)];
        }

        return gameService.startNewGame(gameConfig)
            .then(function() {
                boardSpinner.hide();
                vm.currentGame = gameService.currentGame;
                vm.gameExists = true;
                vm.paused = false;
                vm.gameStats.currentRound++;
                $scope.$broadcast(GAME_EVENTS.GAME_STARTED, gameService.currentGame);
            })
            .catch(handleError);
    }

    function play() {
        var nextPlayer = vm.gameConfig.players[gameService.currentGame.nextPlayer];
        if (nextPlayer.type === PLAYER_TYPES.AI) {
            deferredMove = $q.defer();
            deferredMove.resolve();
        } else if (nextPlayer.type === PLAYER_TYPES.HUMAN) {
            if (isHumanVsAiGame()) {
                showToast("Your turn, human!");
            }
            deferredMove = $q.defer();
        }

        deferredMove.promise
            .then(function(selectedCell) {
                if (!isAiVsAiGame()) {
                    boardSpinner.show();
                }
                return gameService.currentGame.playTurn(selectedCell);
            })
            .then(function(result) {
                boardSpinner.hide();
                if (vm.gameExists && !vm.paused) {
                    result.highlight = nextPlayer.type === PLAYER_TYPES.AI
                    $scope.$broadcast(GAME_EVENTS.MOVE_COMPLETED, result);
                } else if (vm.gameExists && vm.paused) {
                    vm.pausedResult = result;
                }
                return result;
            })
            .then(function(result) {
                if (!vm.gameExists) {
                    return;
                } else if (!result.gameEnded && !vm.paused) {
                    play();
                } else if (result.gameEnded) {
                    updateStats(result);
                    gameService.endCurrentGame(); // async, but we don't care whether it succeeds or fails
                    if (vm.gameStats.currentRound < vm.gameConfig.rounds) {
                        initRound().then(play);
                    } else {
                        endGame(); // async, but don't care about result
                    }
                }
            })
            .catch(function(response) {
                if (vm.gameExists) {
                    return handleError(response);
                } else {
                    return $q.reject(response);
                }
            });
    }


    function isHumanVsAiGame() {
        var types = _.pluck(vm.gameConfig.players, "type");
        return _.includes(types, PLAYER_TYPES.AI) && _.includes(types, PLAYER_TYPES.HUMAN);
    }

    function isAiVsAiGame() {
        var types = _.pluck(vm.gameConfig.players, "type");
        return _.includes(types, PLAYER_TYPES.AI) && !_.includes(types, PLAYER_TYPES.HUMAN);
    }

    function isHumanVsHumanGame() {
        var types = _.pluck(vm.gameConfig.players, "type");
        return !_.includes(types, PLAYER_TYPES.AI) && _.includes(types, PLAYER_TYPES.HUMAN);
    }


    function showToast(message, additionalOptions) {
        var options = _.extend({
            template: '<md-toast>{{vm.message}}</md-toast>',
            position: "bottom left",
            locals: {
                message: message
            },
            bindToController: true,
            controller: "GameController.ToastController",
            controllerAs: "vm"
        }, additionalOptions);
        return $mdToast.show(options);
    }

    function showProgressToast(message, additionalOptions) {
        return showToast(message, _.extend({
            template: '<md-toast><md-progress-circular md-mode="indeterminate" md-diameter="48px"></md-progress-circular>&nbsp;{{vm.message}}</md-toast>',
            hideDelay: 0
        }, additionalOptions));
    }

    function showErrorToast(message, additionalOptions) {
        return showToast(message, _.extend({
            template: '<md-toast md-theme="error"><span flex>{{vm.message}}</span><md-button ng-click="vm.hide()" class="md-action md-icon-button" aria-label="close"><md-icon md-svg-icon="close"></md-icon></md-button></md-toast>',
            hideDelay: 0
        }, additionalOptions));
    }



    function resetStats() {
        vm.gameStats = {
            currentRound: 0,
            ties: 0,
            wins: {}
        };
        vm.gameStats.wins[PIECES.X] = 0;
        vm.gameStats.wins[PIECES.O] = 0;

        return $q.when();
    }

    function updateStats(result) {
        if (result.gameEnded) {
            if (result.winner) {
                vm.gameStats.wins[result.winner]++;
            } else {
                vm.gameStats.ties++;
            }
        }
        return $q.when();
    }

    function setPaused(isPaused) {
        vm.paused = isPaused;
        if (!isPaused) {
            if (vm.pausedResult) {
                $scope.$broadcast(GAME_EVENTS.MOVE_COMPLETED, vm.pausedResult);
                vm.pausedResult = undefined;
            }
            play();
        }
    }

    function endGame() {
        vm.gameExists = false;
        vm.paused = false;
        vm.currentGame = undefined;
        deferredMove = undefined;
        $mdToast.hide();
        boardSpinner.hide();
        if (gameService.currentGame) {
            return gameService.endCurrentGame();
        } else {
            return $q.when();
        }
    }

    function selectHumanPlayerMove(event, selectedCell) {
        if (!vm.gameExists) {
            return;
        }
        if (vm.gameConfig.players[gameService.currentGame.nextPlayer].type !== PLAYER_TYPES.HUMAN) {
            return;
        }
        if (gameService.currentGame.board[selectedCell.row][selectedCell.column]) {
            return;
        }

        deferredMove.resolve(selectedCell);
    }


    function handleError(response) {
        boardSpinner.hide();

        var message;
        if (response.status === 404) {
            message = "Game no is longer active. Games are automatically deleted after 15 minutes of inactivity.";
        } else {
            message = "Oops. The game ended to an error. Sorry.";
        }

        showErrorToast(message).then(endGame);
        return $q.reject(response);
    }


    function screenIsSmall() {
        return !$mdMedia("gt-xs");
    }

    function handleSmallScreen(screenIsSmall) {
        vm.screenIsSmall = screenIsSmall;
    }

    function toggleConfigMode() {
        vm.showConfig = !vm.showConfig;
        $timeout(function() {
            if (!vm.showConfig) {
                $scope.$broadcast(GAME_EVENTS.RESIZE_BOARD);
            }
        }, 300, false);
    }
}

function ToastController($mdToast) {
    var vm = this;

    vm.hide = hide;

    function hide() {
        $mdToast.hide();
    }
}
},{"angular":"angular","lodash":"lodash"}],4:[function(require,module,exports){
"use strict";

var angular = require("angular");
var _ = require("lodash");

angular.module("ticTacToe")
    .factory("gameService", gameService);

function gameService($http, $q) {

    var service = {
        currentGame: undefined,
        getPlayers: getPlayers,
        startNewGame: startNewGame,
        endCurrentGame: endCurrentGame
    };
    return service;


    function getPlayers() {
        return $http.get("players")
            .then(function(response) {
                return response.data;
            });
    }

    function startNewGame(gameParams) {
        return service.endCurrentGame()
            .then(function() {
                return $http.post("games", gameParams);
            })
            .then(function(response) {
                service.currentGame = new Game(response.data);
            });
    }

    function endCurrentGame() {
        if (!service.currentGame) {
            return $q.when();
        }
        return $http.delete("games/"+service.currentGame.id)
            .then(function() {
                service.currentGame = undefined;
            });
    }

    function Game(initialGameData) {
        var self = this;
        _.extend(self, initialGameData);

        this.playTurn = playTurn;

        function playTurn(selectedMove) {
            return $http.post("games/"+self.id+"/turns", {
                turnNumber: self.turnNumber,
                move: selectedMove
            }).then(function(response) {
                _.extend(self, response.data);
                var move = response.data.move;
                self.board[move.cell.row][move.cell.column] = move.piece;
                return response.data;
            });
        }
    }

}


},{"angular":"angular","lodash":"lodash"}],5:[function(require,module,exports){
require("./game-service");
require("./game-controller");
},{"./game-controller":3,"./game-service":4}],6:[function(require,module,exports){
"use strict";

var angular = require("angular");
require("angular-material");

var PIECES = {
    X: "X",
    O: "O"
};

var GAME_EVENTS = {
    GAME_STARTED: "game started",
    MOVE_COMPLETED: "move completed",
    MOVE_SELECTED: "move selected",
    RESIZE_BOARD: "resize board",
    SHOW_LAST_MOVE: "show last move"
};

var PLAYER_TYPES = {
    AI: "AI",
    HUMAN: "HUMAN"
};

angular
    .module("ticTacToe", ["ngMaterial"])
    .constant("GAME_EVENTS", GAME_EVENTS)
    .constant("PIECES", PIECES)
    .constant("PLAYER_TYPES", PLAYER_TYPES)
    .config(configureIcons)
    .config(configureThemes);

require("./board");
require("./game");
require("./utils");


function configureIcons($mdIconProvider) {
    $mdIconProvider
        .icon("play", "resources/material-design-icons/ic_play_arrow_black_24px.svg")
        .icon("pause", "resources/material-design-icons/ic_pause_black_24px.svg")
        .icon("stop", "resources/material-design-icons/ic_stop_black_24px.svg")
        .icon("forward", "resources/material-design-icons/ic_forward_black_24px.svg")
        .icon("close", "resources/material-design-icons/ic_close_black_24px.svg")
        .icon("settings", "resources/material-design-icons/ic_settings_black_24px.svg")
    ;
}

function configureThemes($mdThemingProvider) {
    $mdThemingProvider.theme("error");
}
},{"./board":2,"./game":5,"./utils":7,"angular":"angular","angular-material":"angular-material"}],7:[function(require,module,exports){
require("./spinner-overlay");
require("./visible-size");
},{"./spinner-overlay":8,"./visible-size":9}],8:[function(require,module,exports){
"use strict";

var angular = require("angular");

angular.module("ticTacToe")
    .factory("spinnerOverlay", spinnerOverlay);


/**
 * Usage: spinnerOverlay(elementId)
 *
 * Creates an object with functions show() and hide().
 * show() overlays a spinner centered in the given element.
 * The element must be *positioned*.
 *
 */
function spinnerOverlay($compile) {

    return create;


    function create(elementId, diameter) {

        var NO_OP = {
            show: function() {},
            hide: function() {}
        };
        var container;
        var overlay;
        var $scope;

        if (!document) {
            return NO_OP;
        }

        container = document.getElementById(""+elementId);
        if (!container) {
            return NO_OP;
        }
        container = angular.element(container);
        $scope = container.scope();


        return {
            show: show,
            hide: hide
        };


        function show() {
            if (!overlay) {
                createOverlay();
            }
        }

        function hide() {
            if (overlay) {
                overlay.remove();
                overlay = undefined;
            }
        }

        function createOverlay() {
            var spinnerTpl = angular.element('<md-progress-circular md-mode="indeterminate" md-diameter="100px">');
            if (diameter !== undefined) {
                spinnerTpl.attr("md-diameter", diameter);
            }
            var overlayTpl = angular.element('<div class="centered">');
            overlayTpl.append(spinnerTpl);

            overlay = $compile(overlayTpl)($scope);
            container.append(overlay);
        }
    }

}
},{"angular":"angular"}],9:[function(require,module,exports){
"use strict";

function getVisibleSize(element) {
    var br = element.getBoundingClientRect();
    var visible = {};

    if (br.top >= 0) {
        visible.height = Math.min(br.height, window.innerHeight - br.top);
    } else {
        visible.height = Math.min(br.bottom, window.innerHeight);
    }

    if (br.left >= 0) {
        visible.width = Math.min(br.width, window.innerWidth - br.left);
    } else {
        visible.width = Math.min(br.right, window.innerWidth);
    }

    return visible;
}

module.exports = getVisibleSize;
},{}]},{},[6]);
